import React from "react";
import logoImg from "../img/shape_logo.svg";


function Home() {

  return (
    <div>
      <img  style={{width: "35%", margin: "auto"}} src={logoImg} alt={{}}/>
    </div>
  )
}

export default Home;