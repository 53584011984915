import '../App.css';
import { useAuth } from "../context/auth";
import { Button } from "../components/AuthForms"

export function NavBar() {
    const { authTokens, setAuthTokens } = useAuth();
    if (authTokens) {
        return LoggedInNavBar(setAuthTokens)
    } else {
        return LoggedOutNavBar()
    }
}


const LoggedInNavBar = (setAuthTokens) => {

    return (
        <div style={{ textAlign: "right" }}>
            <NavBarButton url="/" text="Home" />
            <LogoutButton />
            <NavBarButton url="/billing" text="Billing" />
            <br></br>
        </div>
    )
}


const LoggedOutNavBar = () => {
    return (
        <div style={{ textAlign: "right" }}>
            <NavBarButton url="/" text="Home" />
            <NavBarButton url="/login" text="Login" />
        </div>
    )
}

const LogoutButton = () => {
    const { setAuthTokens } = useAuth();

    const logout = () => {
        setAuthTokens()
    }
    return (
        <div style={{ display: "inline-block" }}>
            <Button onClick={logout}>
            <a href="/">
                Logout
            </a>
            </Button>
        </div>
    )
}

const NavBarButton = ({ url, text }) => {
    return (
        <div style={{ display: "inline-block" }}>
            <Button>
                <a href={url}>
                    {text}
                </a>
            </Button>
        </div>
    )
}

export default NavBar;