import { authorizedPost } from "../RequestWrapper";
import { URLS } from "../App";
import { useStripe } from '@stripe/react-stripe-js';
import { useAuth } from '../context/auth'
import { useState } from 'react'

export function Checkout() {

    const [coupon, setCoupon] = useState('')
    const [error, setError] = useState(false)

    function handleCodeChange(e) {
        setCoupon(e.target.value);
    }

    return (
        <div>
            Welcome to Shape. Please choose a plan to start using advanced features
            of your ShapeScale today!
            <br /><br />
            <Product
                name="yearly"
                interval="year"
                description="Up to 5 accounts unlock advanced Shape metrics."
                cost="$129/year"
                coupon={coupon}
                setError={setError}
            />
            <br></br>
            <Product
                name="monthly"
                interval="month"
                description="Up to 5 accounts unlock advanced Shape metrics."
                cost="$19/month"
                coupon={coupon}
                setError={setError}
            />
            <form>
                <label>
                    Coupon:
                    <input type="text" name="coupon" onChange={handleCodeChange}></input>
                    {
                        error === 'BAD_CODE' &&
                        <div>
                            Issue with Coupon
                        </div>
                    }
                </label>
            </form>
            {
                error === 'NO_ADDRESS' &&
                <div>
                    Please update address in the Stripe Billing Portal.
                </div>
            }
        </div>
    )
};

function Product({ name, interval, description, cost, coupon, setError }) {

    const { authTokens, setAuthTokens } = useAuth()
    const stripe = useStripe();

    function select(event) {
        authorizedPost(
            URLS.checkoutSessionUrl,
            authTokens,
            setAuthTokens,
            { 'interval': event.target.value, 'coupon': coupon }
        ).then(
            res => {
                if (res.status === 200) {
                    stripe.redirectToCheckout({
                        sessionId: res.data.sessionId
                    })
                } else if (res.status === 400) {

                    let status = res.data.status
                    if (status === 'BAD_CODE') {
                        setError('BAD_CODE')
                    } else if (status === 'NO_ADDRESS') {
                        setError('NO_ADDRESS')
                    }
                }
            }
        )
    }

    return (
        <div>
            <div>
                {name}:
            </div>
            <div>
                {description}
            </div>
            <div>
                {cost}
            </div>
            <button value={interval} onClick={select}>
                Select
            </button>
        </div>
    )
}

export default Checkout;
