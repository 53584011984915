import axios from 'axios';
import { Redirect } from 'react-router-dom';
import { URLS } from "./App"

export async function authorizedPost(
    url, authTokens, setAuthTokens, data = {}, config = {}
) {

    config.headers = {
        Authorization: authTokens.access_token
    }

    try {
        let resp = await axios.post(
            url, data, config
        )
        return resp
    } catch (error) {
        if (error.response.status === 401) {
            const success = await getNewTokens(authTokens, setAuthTokens)
            if (success) {
                config.headers.Authorization = authTokens.access_token
                let resp = await axios.post(url, data, config)
                return resp
            }
            
        } else {
            return error.response;
        }
    }
}

export async function authorizedGet(
    url, authTokens, setAuthTokens, config = {}
) {

    config.headers = {
        Authorization: authTokens.access_token
    }

    try {
        let resp = await axios.get(url, config)
        return resp
    } catch (error) {
        if (error.response.status === 401) {
            const success = await getNewTokens(authTokens, setAuthTokens)
            if (success) {
                config.headers.Authorization = authTokens.access_token
                let resp = await axios.get(url, config)
                return resp
            }
        }
    }
}


async function getNewTokens(authTokens, setAuthTokens) {

    const params = new URLSearchParams()
    params.append('grant_type', 'refresh_token')
    params.append('client_id', 'public')
    params.append('refresh_token', authTokens.refresh_token)

    try {
        let res = await axios.post(URLS.keycloakToken, params)
        let tokens = {
            'access_token': "keycloak:"+res.data.access_token,
            'refresh_token': res.data.refresh_token
        }
        setAuthTokens(tokens)
        authTokens.access_token = tokens.access_token
        authTokens.refresh_token = tokens.refresh_token
        return true
    } catch (error) {
        if (
            error.response.status === 400
        ) {
            setAuthTokens();
            return <Redirect to="/login" />
        }
        return false
    }
}

