import React, { useState } from "react";
import { Redirect } from 'react-router-dom';
import axios from 'axios';
import { URLS } from "../App";
import { useAuth } from "../context/auth";

function randomAlphaNumeric(length) {
    var result           = '';
    var characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    var charactersLength = characters.length;
    for ( var i = 0; i < length; i++ ) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
   }
   return result;
}

export function Login() {

    let state = randomAlphaNumeric(6)
    let params = new URLSearchParams();
    params.append("client_id", "public");
    params.append("response_type", "code");
    params.append("state", state);
    params.append("redirect_uri", URLS.app + "/login-redirect")
    localStorage.setItem("state", state);
    window.location = URLS.keycloakAuth + '?' + params.toString();
    
    return (
        <div></div>
    );
}

export function LoginRedirect() {
    const { setAuthTokens } = useAuth();
    const [doAuthGrant, setDoAuthGrant] = useState(true);

    const authorizationGrant = async function() {

        const urlSearchParams = new URLSearchParams(window.location.search);
        const responseParams = Object.fromEntries(urlSearchParams.entries());
        if (localStorage.getItem("state") !== responseParams.state) {
            return <Redirect to="/login" />
        }

        const params = new URLSearchParams()
        params.append('grant_type', 'authorization_code')
        params.append('client_id', 'public')
        params.append('code', responseParams.code)
        params.append("redirect_uri", URLS.app + "/login-redirect")

        console.log("before")
        let res = await axios.post(URLS.keycloakToken, params)
        setAuthTokens({
            'access_token': "keycloak:"+res.data.access_token,
            'refresh_token': res.data.refresh_token
        })
        window.location = '/'
        }

    if (doAuthGrant) {
        setDoAuthGrant(false)
        authorizationGrant()
    }
    return (
        <div></div>
    );
}
