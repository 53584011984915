import { authorizedGet, authorizedPost } from "../RequestWrapper";
import { URLS } from "../App";
import { useAuth } from '../context/auth'
import { useState } from 'react'
import { Checkout } from "./Checkout"
import { useStripe } from '@stripe/react-stripe-js';


function penniesToDollar(pennies) {
    var dollars = pennies / 100;
    return dollars.toLocaleString("en-US", {style:"currency", currency:"USD"});
}

export function Billing() {
    const { authTokens, setAuthTokens } = useAuth()
    const [billingData, setBillingData] = useState(0)
    const stripe = useStripe();

    // Initialize state as 0 and then check here. Without the if statement,
    // the componnent re-renders recursively. Without the elif, the function makes 2 calls
    // to the backend.
    if (billingData === 0) {
        setBillingData(1)
        authorizedGet(
            URLS.billing, authTokens, setAuthTokens
        ).then(
            result => {
                setBillingData(result.data)
            }
        )   
    } else if (billingData === 1) {
        return (
            <div></div>
        )
    }

    function goToStripeDashboard() {
        authorizedPost(
            URLS.customerPortalUrl,
            authTokens,
            setAuthTokens
        ).then(
            res => {
                window.location.href = res.data.url;
            }
        )
    }

    function goToSetupCustomer() {
        authorizedPost(
            URLS.setupCustomer,
            authTokens,
            setAuthTokens
        ).then(
            res => {
                stripe.redirectToCheckout({
                    sessionId: res.data.sessionId
                })
            }
        )
    }

    function unsubscribe() {
        authorizedPost(
            URLS.unsubscribe,
            authTokens,
            setAuthTokens
        ).then(
            res => {
                window.location.href = '/billing'
            }
        )
    }

    function resubscribe() {
        authorizedPost(
            URLS.resubscribe,
            authTokens,
            setAuthTokens
        ).then(
            res => {
                window.location.href = '/billing'
            }
        )
    }

    function payLastInvoice() {
        authorizedPost(
            URLS.payLastInvoice,
            authTokens,
            setAuthTokens
        ).then(
            res => {
                window.location.href = '/billing'
            }
        )
    }

    function payUnpaidDues() {
        authorizedPost(
            URLS.payUnpaidDues,
            authTokens,
            setAuthTokens
        ).then(
            res => {
                window.location.href = '/billing'
            }
        )
    }

    return (
        <div>
            {
                billingData.can_redirect_to_stripe_dashboard === true &&
                <div>
                    <button onClick={goToStripeDashboard}>Stripe Billing Portal</button>
                </div>
            }
            {
                billingData.see_checkout === true &&
                <Checkout />

            }
            {
                billingData.allow_setup === true &&
                <div>
                    <button onClick={goToSetupCustomer}>Enter Billing Info on Stripe</button>
                </div>

            }
            {
                billingData.allow_resubscribe === true &&
                <div>
                    <div>
                        Your benefits will expire on
                        {billingData.benefits_expiry}
                    </div>
                    <button onClick={resubscribe}>Resubscribe</button>
                </div>
            }
            {
                billingData.can_unsubscribe === true &&
                <div>
                    <button onClick={unsubscribe}>Unsubscribe</button>
                </div>
            }
            {
                // A balance is money owed in an account, so a negative value means the money belongs
                // to the user.
                billingData.can_unsubscribe === true && billingData.balance < 0 &&
                <div>
                    Cancelling will void the positive balance you have of 
                    {penniesToDollar(Math.abs(billingData.balance))}. 
                    Please contact customer support.
                </div>
            }
            {
                billingData.status &&
                <div>
                    Status: {billingData.status}
                </div>
            }
            {
                billingData.pay_last_invoice === true &&
                <div>
                    Please navigate to the Stripe Portal and update your credit card info.
                    Then click below:
                    <br></br>
                    <button onClick={payLastInvoice}>Pay last Invoice</button>
                </div>   
            }
            {
                billingData && billingData.unpaid_dues !== 0 &&
                <div>
                    You owe {penniesToDollar(billingData.unpaid_dues)} in unpaid invoices.
                    Please navigate to the Stripe Portal and update your credit card info.
                    Then click below:
                    <br></br>
                    <button onClick={payUnpaidDues}>Pay unpaid dues</button>
                </div>   
            }

        </div>
    )
}


export default Billing;
